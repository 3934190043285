import React from "react";
import { CopyRights, FooterLinks, FooterSection, SocialLinks } from "./styles";

import Logo from "src/assets/images/revise.svg";
import { Link } from "react-router-dom";
import {
  DiscordIcon,
  FacebookIcon,
  InstaIcon,
  TwitterIcon
} from "src/constants/index";

const Footer = () => {
  return (
    <>
      <div className="gradientLine"></div>
      <FooterSection>
        <div className="container">
          <SocialLinks>
            <img src={Logo} alt="Revise" />
            <p>
              Revise offers a powerful back-end to program NFTs to interact with
              applications, utilities and data feeds.
            </p>
            <div className="socialShare">
              <Link to="https://www.facebook.com/ReviseNFT">
                <FacebookIcon />
              </Link>
              <Link to="https://twitter.com/ReviseNFT">
                <TwitterIcon />
              </Link>
              <Link to="https://www.instagram.com/revisenft">
                <InstaIcon />
              </Link>
              <Link to="https://discord.com/invite/aMRnzBR5Pj">
                <DiscordIcon />
              </Link>
            </div>
          </SocialLinks>
          <FooterLinks>
            <div>
              <h2>Resources</h2>
              <ul>
                <li>
                  <Link to="https://blog.revise.network">Blog</Link>
                </li>
                <li>
                  <Link to="https://docs.revise.network/api-docs">
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link to="https://www.npmjs.com/package/revise-sdk">
                    Download SDK
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2>Company</h2>
              <ul>
                <li>
                  <Link to="https://linktr.ee/revise_network">About</Link>
                </li>
                <li>
                  <Link to="https://apply.workable.com/revisenft">Careers</Link>
                </li>
                {/* <li><Link to="/">Terms</Link></li>
                <li><Link to="/">Privacy</Link></li> */}
              </ul>
            </div>
            <div>
              <h2>Support</h2>
              <ul>
                <li>
                  <Link to="https://linktr.ee/revise_network">Contact</Link>
                </li>
                {/* <li><Link to="/">FAQ</Link></li> */}
                <li>
                  <Link to="https://calendly.com/anil-revise/revise-demo">
                    Request Demo
                  </Link>
                </li>
              </ul>
            </div>
          </FooterLinks>
        </div>
      </FooterSection>
      <CopyRights>
        <div className="container">
          <p>Copyright © 2023 Revise Innovations.</p>
          <p>Legal</p>
        </div>
      </CopyRights>
    </>
  );
};

export default Footer;
