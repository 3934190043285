import styled from "styled-components";

export const Banner = styled.section`
  padding: 120px 0 74px;
  position: relative;
  overflow : hidden;
`;

export const Container = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  .content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 46px 42px 46px 46px;
    h1 {
      background: linear-gradient(180deg, #545454 0%, #000000 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 54px;
      line-height: 103.5%;
      letter-spacing: -2.5px;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #121212;
      max-width: 370px;
    }
    .heroBtns {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      padding: 15px 24px;
      border-radius: 30px;
      outline: 1px solid #ffffff;
      text-decoration: none;
      color: #ffffff;
      display: flex;
      align-items: center;
      gap: 6px;
      width: fit-content;
      background: #056af9;
      white-space: nowrap;
      &:last-child {
        color: #000000;
        outline: 1px solid #000000;
        background: #ffffff;
      }
    }
  }
  .videoWrapper {
    border-radius: 10px;
    video{
      padding: 0 8px;
    }
  }

  &#tweet {
    margin-bottom : 56px;
    div{
      width : 100%;
    }
  }
  div[twdiv="yes"] {
    width: 100%;
  }
  .twitter-timeline.twitter-timeline-rendered{
    width : 100%;
    height : 600px;
    overflow-y : scroll;
  }
  @media (max-width : 992px){
    flex-wrap : wrap;
    .content{
      width : 100%;
    }
    .videoWrapper{
      width : 100%;
      img{
        width : 100%;
      }
    }
  }
  @media (max-width : 992px){
    .content{
      padding : 20px;
      h1{
        font-size : 36px;
      }
      p{
        font-size : 16px;
      }
      .heroBtns{
        flex-wrap : wrap;
      }
    }
  }
  @media (max-width : 480px){
    .content{
      a{
        width : 100%;
        justify-content : center;
      }
    }
  }
`;

export const Traits = styled.section`
  padding-bottom: 40px;
  .heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 24px;
    flex-wrap : wrap;
    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 110%;
      letter-spacing: -1px;
      color: #000000;
      padding-bottom: 16px;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #121212;
      max-width: 352px;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #ffffff;
      border: 1px solid #bcbcbc;
      box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      cursor: pointer;
      svg{
        transition : all 0.3s ease-in-out;
      }
      &.active{
        position: relative;
        svg{
          transform : rotate(180deg)
        }
        &::after {
          content: '';
          height: 30px;
          width: 100%;
          position: absolute;
          bottom: -22.5px;
          background: white;
          left: -1px;
          border-right: 1px solid rgb(188, 188, 188);
          border-left: 1px solid rgb(188, 188, 188);
      }
      }
    }
  }
  @media (max-width:768px){
    .heading{
      .headingContainer{
        width : 100%;
      }
      h2{
        font-size : 32px;
      }
      p{
        font-size : 16px;
      }
      h4{
        font-size : 18px;
        padding: 12px 16px;
      }
    }
  }
`;

export const Work = styled.div`
  margin-top: 20px;
  display: flex;
  background: #ffffff;
  border-radius: 10px 0 10px 10px;
  border : 1px solid rgb(188, 188, 188);
  .imgWrapper {
    padding: 58px 44px;
    background: #d7ff3a;
    border: 1px solid #bcbcbc;
    box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .traitsContent {
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding: 34px 26px 48px 34px;
    max-width: 474px;
  }
  p{
    font-weight: 500;
    font-size: 20px;
    color: #121212;
    line-height: 150%;
  }
  span {
    width: 100%;
    height: 1px;
    background: #cdcdcd;
  }
  .socialShare {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #121212;
      & + div {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    a {
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #121212;
      text-decoration: underline;
      padding-top: 12px;
    }
  }
  @media (max-width: 768px){
    flex-wrap : wrap;
    border-radius: 0 10px 10px 10px;
    .imgWrapper{
      padding : 20px;
      margin : 12px;
    }
    .traitsContent{
      padding : 20px;
      max-width : 100%;
      p{
        font-size : 16px;
      }
    }
    .socialShare{
      p{
        font-size : 20px;
      }
      a{
        font-size : 16px;
      }
    }
  }
`;

export const Table = styled.div`
  padding-bottom: 132px;
`;

export const TableContainer = styled.div`
  background: #ffffff;
  border: 1px solid #bcbcbc;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow-x : overlay;
  &::-webkit-scrollbar{
    background : transperent;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 4px;
    background-color: #F5F5F5;
    height : 4px;
    margin-top : -4px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(216, 216, 216);
    height : 4px;
  }
  .tableContent{
    width: 1110px;
  }
`;

export const TableHead = styled.div`
  padding: 18px 22px;
  display: flex;
  align-items: center;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    &:first-child {
      width: 310px;
    }
    &:nth-child(2) {
      width: 218px;
    }
    &:nth-child(3) {
      width: 452px;
    }
    &:last-child {
      width: 82px;
      text-align: right;
      justify-content : flex-end;
    }
  }
`;

export const TableRow = styled.a`
  padding: 14px 22px;
  display: flex;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  text-decoration: none;
  &:last-child:hover {
    border-radius: 0 0 10px 10px;
  }
  &:hover {
    background: #f7ffd8;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 16px;
    &:first-child {
      width: 310px;
    }
    &:nth-child(2) {
      width: 218px;
    }
    &:nth-child(3) {
      width: 444px;
    }
    &:last-child {
      width: 92px;
      text-align: right;
      justify-content : flex-end;
    }
  }
  .traitImg {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
  }
`;
