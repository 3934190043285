import React from "react";
import Header from "src/components/Header/index";
import { Link } from "react-router-dom";
import { ArrowSvg, ReadSvg } from "src/constants/index";


import { Container, Banner } from "./styles";

const Hero = () => {
  return (
    <Banner>
      <Header />
      <div className="container">
        <Container>
          <div className="content">
            <h1>Build More Than JPEGs.</h1>
            <p>
              Revise enables you to set dynamic traits for your NFTs, enabling them
              to update based on current events or specific actions.
            </p>
            <div className="heroBtns">
              <Link to="https://app.revise.network/">
                Get Started
                <ArrowSvg />
              </Link>
              <Link to="https://docs.revise.network/api-docs" className="commonBtn">
                <ReadSvg />
                Read the Docs
              </Link>
            </div>
          </div>
          <div className="videoWrapper">
            {/* <img src={Vid} alt="video" /> */}
            <video playsInline autoPlay muted loop width="100%" height="100%">
              <source src="https://revise-testing.fra1.cdn.digitaloceanspaces.com/Hero-Video-NFT-Trait.mp4" />
            </video>
          </div>
        </Container>
      </div>
    </Banner>
  );
};

export default Hero;
