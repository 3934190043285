import styled from "styled-components";

export const Head = styled.header`
  padding: 18px 0;
  width: 100%;
  position: absolute;
  top: 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .options{
    width: 20px;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 99;
    display: none;
    span{
      height: 2px;
      width: 20px;
      background: #000000;
      margin-bottom: 3px;
      display: block;
    }
    &.hide{
      span:first-child{
        display : none;
      }
      span:last-child{
        display : none;
      }
    }
  }
  @media (max-width : 992px){
    .options {
      display : inline-block;

    }
  }
`;
export const Logo = styled.div`
z-index: 99;
`;

export const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  gap: 58px;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    text-decoration: none;
    letter-spacing: 0.2px;
  }
  @media (max-width : 992px){
    display : none;
  }
`;

export const NavButtons = styled.ul`
  display: flex;
  list-style: none;
  gap: 12px;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #121212;
    text-decoration: none;
    letter-spacing: 0.2px;
    padding: 12px 20px;
    display: block;
  }
  li:last-child a {
    color: #ffffff;
    background: #056af9;
    border-radius: 52px;
    font-weight : 700;
  }
  @media (max-width : 992px){
    display : none;
  }
`;

export const MobileNav = styled.div`
  padding: 80px 24px 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 100%;
  transition: all 0.3s ease-in-out;
  background-color: #EDEDED;
  display: block;
  z-index: 9;
  .mobile-nav-links{
    width: 100%;
    list-style : none;
    a{
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: rgb(0, 0, 0);
      text-decoration: none;
      letter-spacing: 0.2px;
      margin-bottom: 24px;
      display: inline-block;
    }
  }
  .mobile-nav-btns{
    width: 100%;
    list-style : none;
    li{
      a{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: rgb(18, 18, 18);
        text-decoration: none;
        letter-spacing: 0.2px;
        display: block;
        margin-bottom: 24px;
      }
      &:last-child{
        a{
          color: rgb(255, 255, 255);
          background: rgb(5, 106, 249);
          border-radius: 52px;
          padding: 12px 20px;
          width : fit-content;
          font-weight : 700;
        }
      }
    }
  }
  @media (max-width : 992px){
    &.active{
      left : 0;
    }
  }
`