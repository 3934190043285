import React, { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableContainer } from "./styles";
import moment from "moment";
import { getNftTraits } from "src/services/api";
import Loader from "src/components/loader/index";
import Logo from "src/assets/images/LogoMark.svg";

const TraitsTable = () => {
  const [traits, setTraits] = useState([]);
  const [loader, setLoader] = useState(true);

  const data = [];

  useEffect(() => {
    fetchTraits();
  }, []);

  const fetchTraits = async () => {
    const res = await getNftTraits();
    if (res.status === 200) {
      setLoader(false);
      if (res.data.length <= 15) {
        setTraits(res.data);
      } else {
        setTraits(res.data.slice(0, 15));
      }
    }
  };

  useEffect(() => {
    loadImage();
  }, [traits]);

  const loadImage = (imageData) => {
    imageData = traits;
    imageData.forEach((img, i) => {
      const image = new Image();
      image.src = img.image;
      image.className = "traitImg";
      image.onerror = () => {
        console.log("Image Invalid !!");
        image.src = Logo;
      };

      if (document.getElementById(`imageToShow-${i}`))
        document.getElementById(`imageToShow-${i}`).appendChild(image);
    });
  };

  return (
    <Table>
      <div className="container">
        <TableContainer>
          <div className="tableContent">
            <TableHead>
              <h3>Item</h3>
              <h3>Traits Changed</h3>
              <h3>NFT ID</h3>
              <h3>Time</h3>
            </TableHead>
            {loader ? (
              <Loader small="small" />
            ) : traits.length ? (
              traits.map((trait, i) => {
                return (
                  <TableRow
                    key={i}
                    href={`https://explorer.revise.network/nft/${trait.nftId}`}
                    target="_blank"
                  >
                    <p>
                      {/* <img
                        className="traitImg"
                        // src={trait?.image.includes("http") ? trait.image : Logo}
                        // src={checkImage(trait.image)}
                        alt="Trait"
                      /> */}
                      <span id={`imageToShow-${i}`}></span>
                      {trait.name}
                    </p>
                    <p>{trait.traitsChanged}</p>
                    <p>{trait.nftId}</p>
                    <p>{moment(trait.nftDate).startOf("second").fromNow()}</p>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <p style={{ width: "100%" }}>No Live Traits</p>
              </TableRow>
            )}
          </div>
        </TableContainer>
      </div>
    </Table>
  );
};

export default TraitsTable;
