import React from 'react'
import Header from 'src/components/Header/index'
import AboutRevised from './AboutRevised'
import InThePress from './InThePress'
import Investors from './Investors'
import YourNfts from './YourNfts'

function AboutPage() {
  return (
<>
    <Header />
    <AboutRevised/>
    <InThePress/>
    <Investors />
    <YourNfts />
</>
  )
}

export default AboutPage