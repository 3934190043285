import React, { useState } from "react";
import { Link } from "react-router-dom";
import BrandLogo from "src/assets/images/logo.svg";

import { Head, Logo, MobileNav, NavButtons, NavLinks } from "./styles";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Head>
      <div className="container">
        <Logo>
          <Link to="/">
            <img src={BrandLogo} alt="Revise" />
          </Link>
        </Logo>

        {/* <NavLinks>
          <li>
            <Link to="https://linktr.ee/revise_network">About Us</Link>
          </li>
          <li>
            <Link to="https://docs.revise.network/api-docs" target="_blank">
              Read Our Docs
            </Link>
          </li>
        </NavLinks> */}
        <NavButtons>
          <li>
              <Link to="https://linktr.ee/revise_network">About Us</Link>
            </li>
            <li>
              <Link to="https://docs.revise.network/api-docs" target="_blank">
                Read Our Docs
              </Link>
          </li>
          <li>
            <Link to="https://app.revise.network">Login</Link>
          </li>
          <li>
            <Link to="https://app.revise.network">Get Started</Link>
          </li>
        </NavButtons>
        <div
          className={openMenu ? "options hide" : "options"}
          onClick={() => setOpenMenu(!openMenu)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <MobileNav className={openMenu ? "active" : ""}>
        {/* <ul className="mobile-nav-links">
          <li>
            <Link to="https://linktr.ee/revise_network">About Us</Link>
          </li>
          <li>
            <Link to="https://docs.revise.network/api-docs" target="_blank">
              Read Our Docs
            </Link>
          </li>
        </ul> */}
        <ul className="mobile-nav-btns">
        <li>
            <Link to="https://linktr.ee/revise_network">About Us</Link>
          </li>
          <li>
            <Link to="https://docs.revise.network/api-docs" target="_blank">
              Read Our Docs
            </Link>
          </li>
          <li>
            <Link to="https://app.revise.network">Login</Link>
          </li>
          <li>
            <Link to="https://app.revise.network">Get Started</Link>
          </li>
        </ul>
      </MobileNav>
    </Head>
  );
};

export default Header;
