import styled from "styled-components";

export const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 116px;
  label {
    color: #000000;
    margin-bottom: 23px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    width: 100%;
    display: inline-block;
  }
  h1 {
    color: #000;
    margin: auto;
    max-width: 772px;
    padding-bottom: 34px;
    text-align: center;
    font-size: 64px;
    font-weight: 700;
    line-height: 79px;
    span {
      font-weight: 700;
      font-size: 54px;
      line-height: 103.5%;
      letter-spacing: -2.5px;
      background: linear-gradient(180deg, #545454 0%, #000000 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }
  a {
    font-weight: 700;
    text-decoration : none;
    font-size: 16px;
    line-height: 22px;
    padding: 15px 24px;
    border-radius: 30px;
    outline: none;
    border: none;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    gap: 6px;
    width: fit-content;
    background: rgb(5, 106, 249);
    margin: auto;
  }
  p {
    color: rgb(18, 18, 18);
    font-size: 48px;
    font-weight: 700;
    line-height: 62px;
    margin: auto;
    max-width: 1283px;
    padding-bottom: 100px;
    padding-top: 110px;
    margin: auto;
  }
  @media (max-width : 768px){
    label{
      font-size : 16px;
      line-height : 26px;
    }
    h1{
      font-size : 40px;
      line-height : 49px;
      padding-bottom: 40px;
      span{
        font-size : 40px;
        line-height : 49px;
        padding-bottom: 40px;
      }
    }
    .aboutusinfo{
      font-size: 36px;
      line-height: 38px;
    }
  }
`;

export const News = styled.section`
  padding: 64px 0 144px;
  margin: auto;
  width: 100%;
  max-width: 100%;
  h2 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 1%;
    line-height: 62px;
    color: #000;
    padding-bottom: 58px;
    text-align: center;
  }
  .newsCard {
    align-items: center;
    display: flex;
    gap: 43px;
    justify-content: center;
  }
  .featured {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 10px 1px;
    border-radius: 24px;
    max-width: 595px;
    padding-bottom: 43px;
    padding-top: 42px;
    label {
      color: #858585;
      margin-bottom: 5px;
      padding-left: 46px;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
    p {
      border-bottom: 1px solid rgb(0 0 0 / 21%);
      color: #000000;
      padding-bottom: 44px;
      padding-left: 46px;
      padding-right: 65px;
      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
    }
  }
  .newsArticle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 45px 46px 0 36.6px;
    a {
      background-color : rgb(5, 106, 249);
      outline : 1px solid rgb(5, 106, 249);
      color : #ffffff;
    }
  }
  .newsLogosContainer {
    padding-top: 10px;
    label {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }

    .newsLogos {
      grid-column-gap: 150px;
      grid-row-gap: 75px;
      -webkit-column-gap: 150px;
      column-gap: 150px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 40px;
      padding-left: 13.5px;
      row-gap: 75px;
    }
  }
  @media (max-width : 768px){
    padding: 32px 0 64px;
    h2{
      font-size: 30px;
      line-height: 38px;
      padding-bottom: 25px;
    }
    .newsCard{
      flex-wrap : wrap;
    }
    .featured{
      padding : 24px 0;
      label{
        font-size: 14px;
        line-height: 18px;
        padding-left : 24px;
      }
      p{
        font-size: 16px;
        line-height: 19px;
        padding: 24px;
      }
    }
    .newsArticle{
      padding: 24px 24px 0 18px;
    }
    .newsLogosContainer {
      max-width: 512px;
      padding : 0;
      label{
        font-size: 16px;
        line-height: 26px;
      }
      .newsLogos{
        padding : 0;
        column-gap: 20px;
      }
  }
  }
`;

export const Invest = styled.section`
  padding: 0 0 177px;
  .investHeading {
    max-width: 690px;
    margin: auto;
    h2 {
      color: #000000;
      padding-bottom: 16px;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      line-height: 62px;
    }
    p {
      color: #000000;
      padding-bottom: 46px;
      text-align: center;
      font-size: 19px;
      font-weight: 400;
      line-height: 32px;
    }
  }
  .investCompanies {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
    margin-bottom: 32px;
    div {
      background-color: hsla(0, 0%, 100%, 0.91);
      border: 1px solid hsla(0, 0%, 100%, 0.1);
      border-radius: 22px;
      flex-grow : 1;
      display : flex;
      justify-content : center;
      align-items : center;
      height : 176px;
    }
  }
  .investPeople {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
  }
  .investCard {
    background-color: hsla(0, 0%, 100%, 0.91);
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    border-radius: 22px;
    padding: 25px 32px;
    h4 {
      color: #000;
      margin-bottom: 2px;
      text-align: center;
      white-space: nowrap;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }
    p {
      color: #000;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
  }
  .investCardMore {
    border: 1px solid #000;
    border-radius: 23px;
    padding: 42px 45px;
    p {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
  }
  @media (max-width : 768px){
    padding: 0 0 80px;
    .investHeading {
      h2{
        font-size : 36px;
      }
      p{
        font-size: 16px;
        padding-bottom: 32px;
      }
    }
    .investCompanies{
      flex-direction : column;
      max-width : 400px;
      margin : 0 auto 32px;
    }
    .investCard{
      width : 46%;
      text-align : center;
    }
    .investCardMore{
      width : 46%;
      text-align : center;
    }
  }
  @media (max-width : 600px){
    .investCard{
      width : 100%;
      text-align : center;
    }
    .investCardMore{
      width : 100%;
      text-align : center;
    }
  }
`;


export const CTA = styled.section`
  padding-bottom: 139px;
  padding-top: 19px;
  h2{
    color: #000;
    padding-bottom: 36px;
    max-width: 504px;
    text-align: center;
    margin : auto;
    font-size: 64px;
    font-weight: 700;
    line-height: 79px;
  }
  .ctaBtns{
    margin : auto;
    max-width : 670px;
    display : flex;
    align-items : center;
    justify-content: space-between;
    a{
      &:first-child{
        background-color : rgb(5, 106, 249);
        outline : 1px solid rgb(5, 106, 249);
        color : #ffffff;
        svg{
          path{
            fill : #ffffff;
          }
        }
      }
    }
    svg{
      path{
        fill : #000000;
      }
    }
  }
  @media (max-width : 768px){
    h2{
      font-size: 36px;
      line-height: 44px;
      padding-bottom: 20px;
    }
    .ctaBtns{
      flex-direction : column;
      gap : 24px;
      .commonBtn{
        width : 100%;
        justify-content : center;
      }
    }
  }
` 