import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "src/components/Footer/index";
import Header from "src/components/Header/index";
import AboutPage from "src/pages/About/index";
import Home from "src/pages/Home/index";

const AppRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/about" element={<AboutPage/>} /> */}
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default AppRoutes;
