import React, { useState } from "react";
import { Traits, Work } from "./styles";
import {
  DiscordSvg,
  DownArrowSvg,
  ReadSvg,
  TelegramSvg,
  TwitterSvg
} from "src/constants/index";
import { Link } from "react-router-dom";


const Livetraits = () => {
  const [openLiveTraits, setOpenLiveTraits] = useState(false);

  return (
    <Traits>
      <div className="container">
        <div className="heading">
          <div className="headingContainer">
            <h2>Recent LiveTraits</h2>
            <p>
              Check out what other developers are building using Revise LiveTraits.
            </p>
          </div>
          <div>
            <h4
              className={openLiveTraits ? "active" : ""}
              onClick={() => setOpenLiveTraits(!openLiveTraits)}
            >
              How it works? <DownArrowSvg />
            </h4>
          </div>
        </div>
        {openLiveTraits ? (
          <Work>
            <div className="imgWrapper">
              {/* <img src={HowItWorks} alt="" /> */}
              <video playsInline autoPlay muted loop width="100%" height="100%">
                <source src="https://revise-testing.fra1.cdn.digitaloceanspaces.com/revise-nodes-video.mp4" />
              </video>
            </div>
            <div className="traitsContent">
              <p>
                Using Revise, you can place programmable code inside your NFT
                metadata, which when connected to a data feed can ship magic to your
                collectors 🪄
              </p>
              <Link to="https://docs.revise.network/api-docs" className="commonBtn">
                <ReadSvg />
                Read the Docs
              </Link>
              <span></span>
              <div className="socialShare">
                <div>
                  <p>Need help?</p>
                  <div>
                    <Link to="https://discord.com/invite/aMRnzBR5Pj">
                      <DiscordSvg />
                    </Link>
                    {/* Remove comment if needed the telegram */}
                    {/* <Link to="">
                    <TelegramSvg />{" "}
                  </Link> */}
                    <Link to="https://twitter.com/ReviseNFT">
                      <TwitterSvg />{" "}
                    </Link>
                  </div>
                </div>
                <Link to="https://revise.network/ContactUs">
                  Build your first dynamic NFT <br />
                  See how to build an NFT trading cards game Publishing Dynamic
                  traits to Opensea
                </Link>
              </div>
            </div>
          </Work>
        ) : (
          ""
        )}
      </div>
    </Traits>
  );
};

export default Livetraits;
