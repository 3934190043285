import styled from "styled-components";

export const FooterSection = styled.footer`
  background-color: #ffffff;
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 72px 24px 52px ;
    gap: 0 24px;
    @media (min-width:1158px){
      padding: 72px 0px 52px;
    }
  }
`;

export const SocialLinks = styled.div`
  img{
    margin-bottom: 21px;
  }
  p{
    font-weight: 400;
    font-size: 18px;
    line-height: 162%;
    color: #7A7A7A;
    margin-bottom: 21px;
    max-width: 378px;
  }
  .socialShare{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 55px;
    gap : 40px;
  }
`;

export const FooterLinks = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 42px 72px;
    flex-wrap : wrap;

    h2{
        font-weight: 400;
        font-size: 18px;
        line-height: 162%;
        margin-bottom: 22px;
        color: #000000;
        letter-spacing: 0.008em;
    }
    ul{
        display: flex;
        flex-direction : column;
        gap : 22px;
        list-style : none;
        a{
            font-weight: 400;
            font-size: 18px;
            line-height: 162%;
            letter-spacing: 0.008em;
            color: #7A7A7A;
            text-decoration : none;
        }
    }
`

export const CopyRights = styled.div`
    padding: 18px 0;
    background-color: #f2f2f2;
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 162%;
        letter-spacing: 0.008em;
        color: #7A7A7A;
    }
`