import React from 'react'
import { Container } from './styles';

import { Timeline } from 'react-twitter-widgets'

const Tweets = () => {

  return (
    <div className="tweets">
        <div className="container">
            <Container id='tweet'>
                <Timeline
                    dataSource={{ sourceType: "profile", screenName: "ReviseNFT" }}
                />
            </Container>
        </div>
    </div>
  )
}

export default Tweets