import React from "react";
import Hero from "./Hero";
import Livetraits from "./Livetraits";
import TraitsTable from "./TraitsTable";
import Tweets from "./Tweets";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; 
import { firebaseConfig } from "src/constant/index";

const Home = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <>
      <Hero />
      <Livetraits />
      <TraitsTable />
      <Tweets />
    </>
  );
};

export default Home;
